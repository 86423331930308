// JsFromRoutes CacheKey 39121ac43a1b7dbe9256726ffce3061e
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/dashboard/brands/:brand_id/models/:model_id/trims'),
  create: /* #__PURE__ */ definePathHelper('post', '/dashboard/brands/:brand_id/models/:model_id/trims'),
  new: /* #__PURE__ */ definePathHelper('get', '/dashboard/brands/:brand_id/models/:model_id/trims/new'),
}
