// JsFromRoutes CacheKey 10f566feb6bd644999415b45d87b52ff
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  table: /* #__PURE__ */ definePathHelper('get', '/dashboard/users/table'),
  index: /* #__PURE__ */ definePathHelper('get', '/dashboard/users'),
  create: /* #__PURE__ */ definePathHelper('post', '/dashboard/users'),
  new: /* #__PURE__ */ definePathHelper('get', '/dashboard/users/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/dashboard/users/:id/edit'),
  update: /* #__PURE__ */ definePathHelper('patch', '/dashboard/users/:id'),
}
