import { Controller } from '@hotwired/stimulus'
import Notiflix from 'notiflix'

export default class extends Controller {
  connect() {
    const flashElement = this.element
    const flashMessages = flashElement.querySelectorAll('[data-flash-type]')

    flashMessages.forEach((message) => {
      const type = message.dataset.flashType
      const content = message.dataset.flashMessage

      if (type === 'error') {
        Notiflix.Notify.failure(content)
      }
      else {
        Notiflix.Notify.success(content)
      }
    })
    flashElement.innerHTML = ''
  }
}
