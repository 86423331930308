import { Controller } from '@hotwired/stimulus'
import './select_styles.css'

export default class CollectionSelectController extends Controller {
  static targets = ['select']
  async connect() {
    const Choices = await import('choices.js')
    this.select = new Choices.default(this.selectTarget, {
      allowHTML: true,
    })
  }

  disconnect() {
    if (this.select)
      this.select.destroy()
  }
}
