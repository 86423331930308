// JsFromRoutes CacheKey fba3ab5ba25073c7c65dec0fad2ce893
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  table: /* #__PURE__ */ definePathHelper('get', '/dashboard/generations/table'),
  selector: /* #__PURE__ */ definePathHelper('get', '/dashboard/generations/selector'),
  index: /* #__PURE__ */ definePathHelper('get', '/dashboard/generations'),
  create: /* #__PURE__ */ definePathHelper('post', '/dashboard/generations'),
  new: /* #__PURE__ */ definePathHelper('get', '/dashboard/generations/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/dashboard/generations/:id/edit'),
  update: /* #__PURE__ */ definePathHelper('patch', '/dashboard/generations/:id'),
}
