// JsFromRoutes CacheKey 5c8c113dd433d2361655f5077bafec23
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  table: /* #__PURE__ */ definePathHelper('get', '/dashboard/brands/table'),
  index: /* #__PURE__ */ definePathHelper('get', '/dashboard/brands'),
  create: /* #__PURE__ */ definePathHelper('post', '/dashboard/brands'),
  new: /* #__PURE__ */ definePathHelper('get', '/dashboard/brands/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/dashboard/brands/:id/edit'),
  update: /* #__PURE__ */ definePathHelper('patch', '/dashboard/brands/:id'),
}
