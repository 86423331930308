import { Controller } from '@hotwired/stimulus'
import api from '../api'

export default class VehicleGenerateDescriptionController extends Controller {
  static targets = ['trimId', 'yearId', 'textArea']
  async generate() {
    const response = await api.dashboardApiArtificialIntelligence.generateDescription({
      query: {
        trim_id: this.trimIdTarget.value,
        year_id: this.yearIdTarget.value,
      },
      responseAs: 'response',
    })

    this.reader = response.body.getReader()
    this.textAreaTarget.value = ''
    while (true) {
      const { done, value } = await this.reader.read()
      if (done)
        break
      const chunkText = new TextDecoder('utf-8').decode(value)
      this.textAreaTarget.value += chunkText
      this.textAreaTarget.dispatchEvent(new Event('input', { bubbles: true, cancelable: true }))
    }
  }
}
