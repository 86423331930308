// JsFromRoutes CacheKey 0990095273c13affeef5c60926a84a5b
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/dashboard/brands/:brand_id/models'),
  create: /* #__PURE__ */ definePathHelper('post', '/dashboard/brands/:brand_id/models'),
  new: /* #__PURE__ */ definePathHelper('get', '/dashboard/brands/:brand_id/models/new'),
}
