import { Controller } from '@hotwired/stimulus'
import api from '../api'

export default class CloudinaryWidgetController extends Controller {
  static values = {
    imagesContainerId: String,
    inputId: String,
    inputName: String,
    multiple: {
      type: Boolean,
      default: false,
    },
    folder: {
      type: String,
      default: 'temp',
    },
    turboAction: {
      type: String,
      default: 'append',
    },
  }

  connect() {
    this.widget = cloudinary.createUploadWidget(
      this.config.bind(this)(),
      this.processEvent.bind(this),
    )
  }

  config() {
    return {
      sources: ['local'],
      cloudName: 'motomic-dev', // TODO: Move to environment variable
      multiple: this.multipleValue,
      uploadSignature: this.uploadSignature,
      folder: this.folderValue,
    }
  }

  open() {
    this.widget.open()
  }

  async processEvent(error, result) {
    if (!error && result) {
      switch (result.event) {
        case 'success':
        {
          const resp = await api.dashboardApiImages.create(
            {
              data: {
                public_id: result.info.public_id,
                input_id: this.inputIdValue,
                input_name: this.inputNameValue,
                images_container_id: this.imagesContainerIdValue,
                turbo_action: this.turboActionValue,
              },
              responseAs: 'response',
              headers: {
                Accept: 'text/vnd.turbo-stream.html',
              },
            },
          )
          Turbo.renderStreamMessage(await resp.text())
          break
        }
        case 'queues-end':
          this.widget.close()
      }
    }
  }

  uploadSignature(callback, query) {
    api.dashboardApiImages.sign({ query }).then(resp => callback(resp))
  }
}
