// JsFromRoutes CacheKey 3a29137dcd38981fe7f908daf10af267
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/dashboard/generations/scrape'),
  create: /* #__PURE__ */ definePathHelper('post', '/dashboard/generations/scrape'),
  extract: /* #__PURE__ */ definePathHelper('get', '/dashboard/generations/scrape/extract'),
}
