// JsFromRoutes CacheKey 036ea90ba36d8de24c4e1b37f429e0f6
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  table: /* #__PURE__ */ definePathHelper('get', '/dashboard/dealerships/table'),
  index: /* #__PURE__ */ definePathHelper('get', '/dashboard/dealerships'),
  create: /* #__PURE__ */ definePathHelper('post', '/dashboard/dealerships'),
  new: /* #__PURE__ */ definePathHelper('get', '/dashboard/dealerships/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/dashboard/dealerships/:id/edit'),
  update: /* #__PURE__ */ definePathHelper('patch', '/dashboard/dealerships/:id'),
}
