import { Application } from '@hotwired/stimulus'
import * as Turbo from '@hotwired/turbo'
import AutoSubmit from '@stimulus-components/auto-submit'
import RailsNestedForm from '@stimulus-components/rails-nested-form'
import { DateTime } from 'luxon'
import TextareaAutogrow from 'stimulus-textarea-autogrow'
import { registerControllers } from 'stimulus-vite-helpers'
import '@hotwired/turbo-rails'
import '@fontsource-variable/montserrat'

window.DateTime = DateTime

Turbo.start()
const application = Application.start()
application.register('nested-form', RailsNestedForm)
application.register('auto-submit', AutoSubmit)
application.register('textarea-autogrow', TextareaAutogrow)
const controllers = import.meta.glob('../../../**/*_controller.js', { eager: true })
registerControllers(application, controllers)

// document.addEventListener('turbo:frame-missing', async (event) => {
//   event.preventDefault()
//   const { url } = event.detail.response
//   event.detail.visit(url)
// })

addEventListener('turbo:before-frame-render', (event) => {
  if (document.startViewTransition) {
    const originalRender = event.detail.render
    event.detail.render = (currentElement, newElement) => {
      document.startViewTransition(() => originalRender(currentElement, newElement))
    }
  }
})
