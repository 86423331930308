// JsFromRoutes CacheKey beed4f1cc9ad69b0c011b36bcf3eb92e
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  table: /* #__PURE__ */ definePathHelper('get', '/dashboard/catalogue_items/table'),
  grid: /* #__PURE__ */ definePathHelper('get', '/dashboard/catalogue_items/grid'),
  index: /* #__PURE__ */ definePathHelper('get', '/dashboard/catalogue_items'),
  create: /* #__PURE__ */ definePathHelper('post', '/dashboard/catalogue_items'),
  new: /* #__PURE__ */ definePathHelper('get', '/dashboard/catalogue_items/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/dashboard/catalogue_items/:id/edit'),
  update: /* #__PURE__ */ definePathHelper('patch', '/dashboard/catalogue_items/:id'),
}
