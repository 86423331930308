import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'

export default class SortableController extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, this.sortableConfig())
  }

  sortableConfig() {
    return {}
  }

  disconnect() {
    this.sortable.destroy()
  }
}
