import api from '../api'
import SortableController from './sortable_controller'

export default class CatalogueItemsSortableController extends SortableController {
  sortableConfig() {
    return {
      ...super.sortableConfig(),
      onEnd: async (evt) => {
        const resp = await api.dashboardCatalogueItems.update({
          params: { id: evt.item.dataset.id },
          data: { catalogue_item: { position: evt.newIndex + 1 } },
          responseAs: 'response',
          headers: {
            Accept: 'text/vnd.turbo-stream.html',
          },
        })
        Turbo.renderStreamMessage(await resp.text())
      },
    }
  }
}
