// JsFromRoutes CacheKey 2e74bf59b8d9cb1a04b3c35d5e134960
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  sign: /* #__PURE__ */ definePathHelper('post', '/dashboard/api/images/sign'),
  create: /* #__PURE__ */ definePathHelper('post', '/dashboard/api/images'),
  remove: /* #__PURE__ */ definePathHelper('delete', '/dashboard/api/images'),
}
